<template>
  <div>
    <loading v-if="pageLoading" />
    <div v-show="!pageLoading" class="container">
      <navigation-component :id="4" />
      <div class="page-row">
        <img class="bg" src="../../assets/oss/pc_about_pg_bg.jpg" />
        <div class="content">
          <img class="bg" src="../../assets/oss/pc_about_content_bg.png" />
          <div style="z-index: 1; position: relative;">
            <div class="nav-list-item">
              <div class="nav-item" :class="orderType == 2 ? 'active' : ''" @click="handleChangeType(2)">开通会员</div>
              <div class="nav-item" :class="orderType == 1 ? 'active' : ''" @click="handleChangeType(1)">充值钻石</div>
            </div>
            <div v-if="orderType == 2" class="vip-detail-item animate__animated animate__fadeInUp">
              <div v-for="(item, index) in vipIconList" :key="index" class="item">
                <img class="icon" :src="item.icon" />
                {{ item.name }}
              </div>
            </div>
            <div v-if="orderType == 2" class="vip-list-item animate__animated animate__fadeInUp">
              <div v-for="(item, index) in vipList" :key="index" class="vip-item" :class="checkedItem.id == item.id ? 'active' : ''" @click="handleChangeCheckedId(item)">
                <div class="sub-title">会员</div>
                <div class="title">{{ item.title }}</div>
                <div class="line"></div>
                <div class="money-item"><span>{{ item.money }} </span> 元</div>
              </div>
            </div>
            <div v-if="orderType == 1" class="zs-list-item animate__animated animate__fadeInUp">
              <div v-for="(item, index) in zsList" :key="index" class="zs-item" :class="checkedItem.id == item.id ? 'active' : ''" @click="handleChangeCheckedId(item)">
                <div class="title"><img src="../../assets/images/pc/zs-icon.png" /> {{ item.zs_count }}</div>
                <div class="line"></div>
                <div class="money-item"><span>{{ item.money }} </span> 元</div>
              </div>
            </div>
            <div class="pay-tip-item">选择支付方式</div>
            <div class="pay-type-list">
              <div class="pay-type zfb" :class="payType == 2 ? 'active' : ''" @click="handleChangePayType(2)"><img src="../../assets/images/pc/ali-pay-icon.png" class="icon" /> 支付宝</div>
              <div class="pay-type wx" :class="payType == 1 ? 'active' : ''" @click="handleChangePayType(1)"><img src="../../assets/images/pc/wx-pay-icon.png" class="icon" />微信</div>
            </div>
            <div class="pay-agree"><p>支付即表示同意偶遇圈<a href="javascript:;" @click="handleAgree()">《用户服务协议》</a></p></div>
          </div>
          <div class="fotter-item">
            <div class="total">应付金额: <span>{{ checkedItem.money || 0 }}</span>元</div>
            <div class="btn" :class="checkedItem.id ? '' : 'not'" @click="handleCreateOrder"><van-loading v-if="submiting" color="#333333" />确认支付</div>
          </div>
        </div>
      </div>
      <footer-component />
    </div>
    <van-dialog v-model="showQrCode" title="微信扫码支付">
      <div class="qrcode-box" id="qrCode" ref="qrCode"></div>
    </van-dialog>
    <van-popup v-model="loginVis" ><!-- @click="closeLogin()" -->
      <LoginR :key="2" />
    </van-popup>
    <van-dialog v-model="agreeVis" closeOnClickOverlay>
      <div style="margin: 15px; line-height: 1.5em;" v-html="content"></div>
    </van-dialog>
  </div>
</template>

<script>

import NavigationComponent from '../../components/NavigationComponent.vue'
import FooterComponent from '../../components/FooterComponent.vue'
import { vipList, zsList, createOrder, pay } from '@/api/index'
import { mapState } from 'vuex'
import Loading from '../../components/Loading.vue'
import { checkImg } from '../../utils/checkImg'
import QRCode from "qrcodejs2"
import "animate.css"
import { defineAsyncComponent, ref } from 'vue';
import { protocoDetail } from '@/api/index'

export default {
  name: 'Recharge',
  metaInfo: {
    title: '特惠充值 - 偶遇圈',
    meta: [
      {
        name: 'description',
        content: '偶遇圈app，是一个为单身男女提供，附近高颜值、高素质的真人交友平台！'
      },
      {
        name: 'keywords',
        content: '偶遇、艳遇、邂逅、偶遇圈、偶遇圈app、偶遇圈下载、同城交友、附近约会'
      }
  ]
  },
  components: {
    NavigationComponent,
    FooterComponent,
    Loading,
    LoginR: defineAsyncComponent(() => import('../../components/Login.vue')) 
  },
  data () {
    return {
      orderType: 2, // 2:vip, 1:钻石
      payType: 2, // 2:支付宝, 1:微信
      checkedItem: {},
      vipIconList: [
        {icon: require('../../assets/images/pc/vip/vip-icon-01.png'), name: '专属标识'},
        {icon: require('../../assets/images/pc/vip/vip-icon-02.png'), name: '搭讪女神'},
        {icon: require('../../assets/images/pc/vip/vip-icon-03.png'), name: '解锁女士'},
        {icon: require('../../assets/images/pc/vip/vip-icon-04.png'), name: '发布动态'},
        {icon: require('../../assets/images/pc/vip/vip-icon-05.png'), name: '看的更久'},
        {icon: require('../../assets/images/pc/vip/vip-icon-06.png'), name: '隐私保护'},
        {icon: require('../../assets/images/pc/vip/vip-icon-07.png'), name: '推送新人'},
        {icon: require('../../assets/images/pc/vip/vip-icon-08.png'), name: '查看访客'},
        {icon: require('../../assets/images/pc/vip/vip-icon-09.png'), name: '看的更多'}
      ],
      vipList: [],
      zsList: [],
      submiting: false,
      pageLoading: true,
      showQrCode: false,
      id: '',
      title: '',
      content: '',
    }
  },
  computed: {
    ...mapState(['token'])
  },
  mounted () {
    checkImg(() => {
      this.pageLoading = false
    })
    this.handleGetPageData()
  },
  setup() {
    const loginVis = ref(false);
    const agreeVis = ref(false);
    const showLogin = () => {
      console.log("showlogin");
      loginVis.value = true;
    };
    const showAgree = () => {
      console.log("showagree");
      this.id = 7  //this.orderType + 5
      this.handleGetAgreeData()
      agreeVis.value = true;
    };
    return {
      loginVis,
      showLogin,
      agreeVis,
      showAgree
    };
  },
  methods: {
    handleGetPageData () {
      vipList({
        device: 3
      }).then(res => {
        this.vipList = res.info
      })
      zsList({
        device: 3
      }).then(res => {
        this.zsList = res.info
      })
    },
    handleChangeCheckedId (item) {
      this.checkedItem = {
        ...item
      }
    },
    handleChangeType (type) {
      this.orderType = type
      this.checkedItem = {}
    },
    handleChangePayType (type) {
      this.payType = type
    },
    handleCreateOrder () {
      const { token, orderType, payType, checkedItem, submiting } = this
      if (submiting) {
        return
      }
      if (!token) {
        //this.$router.push({ path: '/login' })
        this.loginVis = true
        return
      }
      if (!checkedItem.id) {
        return
      }
      const params = {
        order_type: orderType,
        pay_type: payType,
        goods_id: checkedItem.id,
        pay_where: 3
      }
      this.submiting = true
      createOrder(params).then(res => {
        if (res.error === 0) {
          this.handlePay(res.info)
        } else {
          this.submiting = false
          this.$dialog.alert({
            message: res.info
          })
        }
      })
    },
    handlePay (id) {
      pay({ id }).then(res => {
        this.submiting = false
        if (res.error === 0) {
          if (this.payType === 2) {
            window.open(res.info, '_blank')
          } else {
            this.handleSetQrCode(res.info)
          }
        } else {
          this.$dialog.alert({
            message: res.info
          })
        }
      })
    },
    handleSetQrCode (text) {
      this.showQrCode = true
      setTimeout(() => {
        new QRCode('qrCode', {
          render: 'canvas',
          width: 150,
          height: 150,
          text
        })
      }, 500)
    },
    handleAgree () {
      protocoDetail({
        id: 7 //this.orderType + 5
      }).then(res => {
        console.log(res)
        this.title = res.info.name
        this.content = res.info.content
        this.agreeVis = true
      })
    }
  }
}
</script>

<style lang="less" scoped>

.van-popup {
  width: 426px;
  height: 438px;
  border-radius: 20px;
  background: transparent;
  .container {
    background: none;
  }
}

.van-dialog {
  width: 80%;
  max-height: 80%;
  overflow-y: scroll;
}

.page-row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1099px;
  /*min-height: 100vh;*/
  .content {
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    width: 1170px;
    height: 855px;
    padding: 0 48px;
    position: relative;
    .nav-list-item {
      margin: 58px 0 0 12px;
      width: 270px;
      height: 46px;
      background-color: #191828;
      border-radius: 23px;
      display: flex;
      justify-content: center;
      align-content: center;
      .nav-item {
        flex: 1;
        height: 46px;
        line-height: 46px;
        text-align: center;
        border-radius: 23px;
        color: #fff;
        font-size: 17px;
        cursor: pointer;
        &.active {
          background: linear-gradient(0deg, #1c1b2c, #4b4867);
          color: #ffc898;
        }
      }
    }
    .vip-detail-item {
      margin-top: 50px;
      display: flex;
      align-items: center;
      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        line-height: 18px;
        color: #fff;
        margin-right: 65px;
        .icon {
          width: 60px;
          height: 60px;
          margin-bottom: 16px;
        }
      }
    }
    .vip-list-item {
      margin-top: 30px;
      width: 100%;
      overflow-y: auto;
      .vip-item {
        width: 190px;
        height: 278px;
        background: linear-gradient(0deg, #1c1b2c, #4b4867);
        margin: 0px 11px !important;
        border-radius: 18px !important;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        color: #fff;
        overflow: hidden;
        margin: 2px;
        &:nth-last-child(1) {
          margin-right: 0;
        }
        &.active {
          margin: 0;
          border: 2px solid #ffc898;
          background: linear-gradient(0deg, #4b4867, #1c1b2c);
          .sub-title {
            color: #dbad89;
          }
          .title {
            color: #ffc898;
          }
          .line {
            border-color: #ffdfbe;
          }
        }
        .sub-title {
          font-size: 21px;
          margin-top: 40px;
        }
        .title {
          font-size: 28px;
          margin-top: 28px;
        }
        .line {
          margin:  40px auto;
          width: 110px;
          height: 1px;
          border-top: 1px solid rgba(255, 255, 255, .2);
          border-bottom: 1px solid transparent;
        }
        .money-item {
          font-size: 21px;
          span {
            font-size: 28px;
          }
        }
      }
    }
    .zs-list-item {
      margin: 30px auto;
      width: 881px;
      height: 406px;
      overflow-y: auto;
      .zs-item {
        width: 190px;
        height: 174px;
        background: linear-gradient(0deg, #1c1b2c, #4b4867);
        margin-right: 35px !important;
        margin-bottom: 26px !important;
        border-radius: 18px !important;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        color: #fff;
        overflow: hidden;
        margin: 2px;
        &:nth-child(4n) {
          margin-right: 0 !important;
        }
        &.active {
          margin: 0;
          border: 2px solid #ffc898;
          background: linear-gradient(0deg, #4b4867, #1c1b2c);
          .title {
            color: #ffc898;
          }
          .line {
            border-color: #ffdfbe;
          }
        }
        .title {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 26px;
          margin-top: 33px;
          img {
            width: 28px;
            margin-right: 12px;
          }
        }
        .line {
          margin:  30px auto;
          width: 110px;
          height: 1px;
          border-top: 1px solid rgba(255, 255, 255, .2);
          border-bottom: 1px solid transparent;
        }
        .money-item {
          font-size: 21px;
          span {
            font-size: 28px;
          }
        }
      }
    }
    .pay-tip-item {
      margin-top: 30px;
      font-size: 17px;
      padding: 0 10px;
      color: #fff;
      line-height: 18px;
    }
    .pay-type-list {
      margin-top: 28px;
      display: inline-flex;
      align-items: center;
      width: 50%;
      .pay-type {
        margin-right: 30px;
        width: 172px;
        height: 40px;
        border-radius: 7px;
        background-color: rgba(255, 255, 255, .2);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 17px;
        cursor: pointer;
        &.active.zfb {
          background-color: #009fe8;
        }
        &.active.wx {
          background-color: #0cbc14;
        }
        .icon {
          width: 22px;
          height: 22px;
          margin-right: 15px;
        }
      }
    }
    .pay-agree {
      display: inline-flex;
      width: 50%;
      font-size: 17px;
      color: white;
      p {
        display: block;
        width: 100%;
        text-align: right;
        a {
          color: #FF7D1A;
        }
      }
    }
    .fotter-item {
      height: 119px;
      position: absolute;
      bottom: 0;
      left: 66px;
      right: 66px;
      border-top: 1px solid rgba(255, 255, 255, .1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .total {
        font-size: 21px;
        color: #fff;
        span {
          font-size: 43px;
        }
      }
      .btn {
        width: 225px;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f7e13e;
        border-radius: 26px;
        font-size: 17px;
        color: #000;
        cursor: pointer;
        &.not {
          background-color: #cdcccc;
        }
      }
    }
  }
}
.qrcode-box {
  width: 150px;
  height: 150px;
  margin: 10px auto;
}
</style>
